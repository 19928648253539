<template>
  <main>
    <div class="chat" id="chat_list">
      <div v-for="(item, index) in list" :key="index">
        <div class="chat-l chat-r" v-if="item.uid == my.id">
          <div class="chat-text">{{ item.contents }}</div>
          <div class="chat-pic">
            <img :src="item.user.userImg" />
          </div>
        </div>
        <div class="chat-l" v-else>
          <div class="chat-pic">
            <!-- <a href="/${(item.uid)?c}.html">
              <img :src="item.user.userImg" />
            </a> -->
            <router-link
              :to="{ name: 'Personal', query: { uid: item.uid } }"
            >
              <span>
                <img :src="item.user.userImg" alt="" />
              </span>
            </router-link>
          </div>
          <div class="chat-text">{{ item.contents }}</div>
        </div>
      </div>
    </div>
  </main>
  <footer>
    <div class="form-field">
      <div class="form">
        <label for="">
          <input
            placeholder="我也来说两句..."
            id="comment"
            v-model="message"
            type="text"
          />
        </label>
      </div>
      <img
        class="submit"
        @click="onSbumit"
        data-id="${(friendId)?c}"
        data-myid="${(my.id)?c}"
        src="http://www.gbw114.com//static/m/images/icon90.png"
        alt=""
      />
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useRoute } from "vue-router";
import axios from "@/api/axios";
import Toast from "@/components/UI/Toast";
import qs from "qs";
export default defineComponent({
  name: "Chat",
  setup() {
    const my = ref(null);
    const list = ref([]);
    const message = ref("");
    const route = useRoute();
    const friendId = ref('');
    const id = route.query.id;
    function getData() {
      axios
        .get("/M/Personal/Chat/" + id, { params: { validate: true } })
        .then((res) => {
          if (res.data.success) {
            my.value = res.data.obj.my;
            list.value = res.data.obj.list;
            friendId.value = res.data.obj.friendId;
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
    }
    getData();
    function onSbumit() {
      axios
        .post(
          "/M/Server/doLetters",
          qs.stringify({
            uid: (my.value as any).id,
            reciveid: friendId.value,
            contents: message.value,
          })
        )
        .then((res) => {
          if (res.data.success) {
              message.value = ''
            getData();
          } else {
            Toast({
              type: "error",
              title: res.data.msg,
            });
          }
        })
        .catch((e) => {
          Toast({
            type: "error",
            title: e.message || e,
          });
        });
    }
    return {
      my,
      list,
      onSbumit,
      message,
    };
  },
});
</script>

<style lang="scss" scoped>
main {
  padding-bottom: 110px;
  min-height: 100vh;
  background-color: #efefef;
  box-sizing: border-box;
}

.user-list li {
  height: 92px;
  padding-left: 75px;
  padding-top: 20px;
  border-bottom: 1px solid #eeeeee;
  box-sizing: border-box;
  position: relative;
}

.user-list .avatar {
  position: absolute;
  left: 10px;
  top: 20px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.user-name {
  color: #444444;
  font-size: 15px;
}

.user-sign {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #999999;
  font-size: 14px;
  margin-top: 10px;
}

.cancel-focus {
  background-color: #fff;
  border: none;
  outline: none;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 72.5px;
  height: 27.5px;
  border-radius: 14px;
  border: solid 1px #ccc;
  line-height: 27.5px;
  text-align: center;
  color: #ccc;
  font-size: 12px;
}

.focus {
  background-color: #fff;
  border: none;
  outline: none;
  position: absolute;
  right: 10px;
  top: 20px;
  width: 72.5px;
  height: 27.5px;
  border-radius: 14px;

  border: solid 1px #b9135a;
  line-height: 27.5px;
  text-align: center;
  color: #b9135a;
  font-size: 12px;
}

.focus img {
  width: 11px;
  height: 11px;
  vertical-align: middle;
  position: relative;
  top: -1px;
}

footer {
  position: fixed;
  height: 56px;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-top: 1px solid #eee;
}

.form-field {
  width: 365px;
  height: 40px;
  margin: 7.987px auto;
  position: relative;
  padding-right: 41px;
  box-sizing: border-box;
  background-color: #f4f4f4;
  line-height: 40px;
}
.form-field input {
  width: 100%;
  height: 40px;
  line-height: 40px;
  outline: none;
  border: none;
  background-color: transparent;
  vertical-align: middle;
  padding-left: 15px;
  box-sizing: border-box;
  font-size: 16px;
}

.form-field .submit {
  width: 18px;
  height: 17.512px;
  position: absolute;
  right: 10px;
  top: 11px;
}

.i-input {
  background: none !important;
  text-align: center;
}

/*发送消息*/
.chat {
  background-color: #efefef;
  box-sizing: border-box;
  padding-top: 17.512px;
  padding-right: 10px;
  padding-left: 10px;
}
.chat-date {
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: #666666;
}
.chat-l {
  margin-bottom: 17.512px;
  overflow: hidden;
}
.chat-pic {
  display: inline-block;
  width: 43.5px;
  height: 43.5px;
  border-radius: 50%;
  vertical-align: top;
}
.chat-pic img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.chat-text {
  display: inline-block;
  background: #fff;
  position: relative;
  padding: 6px 18px 6px 13px;
  box-sizing: border-box;
  max-width: 242.512px;
  margin-left: 12px;
  border-radius: 5px;
  font-size: 14px;
  line-height: 24px;
  color: #444444;
  word-wrap: break-word;
}
.chat-text::after {
  content: "";
  position: absolute;
  top: 13px;
  width: 0;
  height: 0;
  border-top: 5px solid transparent;
  border-bottom: 5px solid transparent;
  border-right: 8.513px solid #fff;
  left: -7.987px;
}
.chat-r {
  text-align: right;
}
.chat-r .chat-text {
  text-align: left;
  background-color: #b9135a;
  color: #fff;
  margin-left: 0;
  margin-right: 12px;
}
.chat-r .chat-text::after {
  border-right: none;
  border-left: 8.513px solid #b9135a;
  left: inherit;
  right: -7.987px;
}
.chat-btn {
  float: right;
  width: 51.5px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  //   background: url(icon97.png) no-repeat center center;
  background-size: 24.5px 24.5px;
  border: none;
}
.chat-btn img {
  width: 24.5px;
  height: 24.5px;
}
.chat .comment_input {
  width: 312.5px;
}
.show {
  display: block;
}
.close {
  display: none;
}
</style>
